<template>
  <div class="videoCollectionItem my-refresh">
<!--    <PullRefresh-->
<!--        :disabled="isRefreshDisable"-->
<!--        v-if="!isLoading"-->
<!--        :loading="loading"-->
<!--        :refreshing="refreshing"-->
<!--        :finished="finished"-->
<!--        @onLoad="onLoad"-->
<!--        @onRefresh="onRefresh"-->
<!--        :isNoData="isNoData"-->
<!--        :error="error"-->
<!--        :is-higeht-max="true"-->
<!--        :hasAdvPagination="true"-->
<!--    >-->

<!--      <div class="swiperBox" v-if="bannerList && bannerList.length">-->
<!--        <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">-->
<!--          <van-swipe-item-->
<!--              v-for="(item, index) in bannerList"-->
<!--              :key="index"-->
<!--              @click="goAdv(item)"-->
<!--          >-->
<!--            <ImgDecypt :src="item.cover" :key="item.cover" />-->
<!--          </van-swipe-item>-->
<!--        </van-swipe>-->
<!--      </div>-->
      <!-- 轮播图5个 -->
      <div class="fiveGrid" v-if="bannerList && bannerList.length">
       <div
         class="rectAdvBox"
         @click="goAdv(item)"
         v-for="item in bannerList.slice(0, 10)"
         :key="item.id"
       >
        <ImgDecypt class="rectAdvIcon" :src="item.cover" />
        <div class="rectName">{{ item.title }}</div>
       </div>
      </div>

      <div class="tagsBox">
        <div class="tag" @click="$router.push({path:'/topicPage',query: {name: tag.sectionName, topicId: tag.sectionID,isAcg: isAcg?1:0}})" v-for="tag in tags" :key="tag.sectionID">{{tag.sectionName.slice(0,4)}}</div>
      </div>

      <van-tabs class="tabs" v-model="active" sticky>
        <van-tab title="最新更新">
          <VideoListItem :isDisabledSlide="true" :subModuleId="subModuleId" :moduleSort ="1"></VideoListItem>
        </van-tab>
        <van-tab title="本周最热">
          <VideoListItem :isDisabledSlide="true" :subModuleId="subModuleId" :moduleSort ="2"></VideoListItem>
        </van-tab>
        <van-tab title="最多观看">
          <VideoListItem :isDisabledSlide="true" :subModuleId="subModuleId" :moduleSort ="3"></VideoListItem>
        </van-tab>
        <van-tab title="十分钟以上">
          <VideoListItem :isDisabledSlide="true" :subModuleId="subModuleId" :moduleSort ="4"></VideoListItem>
        </van-tab>
      </van-tabs>

<!--    </PullRefresh>-->
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh/index.vue";
import FourGridHorizontal from "@/components/FourGridHorizontal/index.vue";
import {querySubModuleList} from "@/api/app";
import {AdType, getAdItem, jumpAdv} from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt/index.vue";
import VideoListItem from "@/views/videoModule/videoListItem/index.vue";

export default {
  name: "VideoCollectionItem",
  props: {
    subModuleId: {
      type: String,
    },
    isAcg:{
      type: Boolean,
    }
  },
  components:{
    VideoListItem,
    // PullRefresh,
    ImgDecypt,
  },
  data(){
    return {
      active:0,
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      tags: [],
      bannerList:[],
      jingangArea: [],
    }
  },
  mounted(){
    this.querySubModuleList('refresh');
    this.bannerList = getAdItem(AdType.VIDEOHOMEADV);
    let appConfig = this.$store.getters.appConfig;
    this.jingangArea = appConfig.jingangArea.list || [];
  },
  methods:{
    goAdv(item) {
      jumpAdv(item);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.querySubModuleList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.querySubModuleList('refresh');
    },
    async querySubModuleList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        subModuleId: this.subModuleId,
      };
      let res = await this.$Api(querySubModuleList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      const list = res.data.allSection || [];
      if (type === "refresh") {
        this.tags = list;
      } else {
        this.tags = this.tags.concat(list);
      }
      if (this.pageNumber === 1 && this.tags.length === 0) {
        this.isNoData = true;
      }
      if (!res.data.hasNext) {
        this.finished = true;
      }
    },
  }
}
</script>
<style scoped lang="scss">
.videoCollectionItem{
  height: calc(100vh - 160px);
  //overflow-y: auto;
  //overflow-x: hidden;
  //-webkit-overflow-scrolling: touch;

  .swiperBox {

    .swipe {
      width: 100vw;
      height: calc(100vw / 2.4);

      .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: rgb(43, 39, 72);
      }

      /deep/ .van-swipe__indicators {
        left: auto;
        right: 0;
        align-items: center;
      }

      /deep/ .van-swipe__indicator {
        width: 6px;
        height: 6px;
        background-color: rgba(148, 214, 218, 0.3);
      }

      /deep/ .van-swipe__indicator--active {
        width: 9px;
        height: 9px;
        background-color: #94d6da !important;
      }

      /deep/ img {
        object-fit: fill !important;
      }

      /deep/ .van-image__error{
        border-radius: 0;
      }
    }
  }
  .fiveGrid {
   padding: 0 10px;
   margin-top: 18px;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   grid-row-gap: 20px;
   grid-column-gap: 12px;
   .rectAdvBox {
    width: 60px;
    .rectAdvIcon {
     height: 60px;
     width: 60px;
     border-radius: 8px;
     overflow: hidden;
    }
    .rectName {
     font-size: 12px;
     color: #333;
     margin-top: 4px;
     overflow: hidden;
     white-space: nowrap;
     text-align: center;
    }
   }
  }

  .tagsBox{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 13px;
      grid-row-gap: 11px;
      margin-top: 20px;
      padding: 0 14px;

    .tag{
      background: #FFFFFF;
      border:solid 2px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #B09FD9;
      font-weight: 600;
      padding: 4px 0;
    }
  }

  .barTopic {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    // justify-content: space-between;
    //padding: 12px 0;
    padding: 0 10px;
    margin-top: 20px;
    font-size: 10px;
    color: #000000;
    text-align: center;
    //border-bottom: solid rgb(230, 230, 230) 1px;
    box-sizing: border-box;
    overscroll-behavior: contain;

    .barTopicItem {
      //width: 86px;
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
    }

    .barTopicItem:last-child {
      //padding-right: 30px;
    }

    .topicImg {
      width: 50px;
      height: 50px;
      margin-bottom: 16px;
    }

    img {
      width: 42px;
      height: 42px;
    }

  }

  .tabs{
    margin-top: 20px;
    //padding: 0 16px;
    /deep/ .rowsBox {
     margin-top: 0;
    }

    /deep/ .van-tabs__wrap{
      background-image: none !important;
      padding: 2px 4px;
      background: #F0F0F3;
      border-radius: 8px;
      height: 33px;
      border:solid 2px #FFFFFF;
      margin: 18px 16px;
      //position: sticky;
      //top: 0px;
    }

    /deep/ .van-tabs__nav{
      background: transparent;
    }

    /deep/ .van-tab{
      padding: 0;
      border:solid 2px #F0F0F3;
    }

    /deep/ .van-tab--active{
      border-radius: 8px;
      border:solid 2px #E6E6E9;
    }

    /deep/ .videoListItem{
      height: 100%;
      //height: calc(100vh - 100px);
      //overflow-y: visible;
    }

    /deep/ .my-refresh{
      overflow-y: visible;
      //height: calc(100vh - 100px);
      //overflow-y: visible;
    }

    /deep/ .van-tabs__line{
      display: none;
    }

    /deep/ .van-sticky--fixed{
      background: #FFFFFF;
      top: 70px;
    }
  }


}
</style>
