<template>
  <div class="videoListItem my-refresh">
<!--  <div class="videoListItem my-refresh" :class="!isDisabledSlide?'overflow-y-auto':''">-->
<!--    <PullRefresh-->
<!--        :disabled="isRefreshDisable"-->
<!--        v-if="!isLoading"-->
<!--        :loading="loading"-->
<!--        :refreshing="refreshing"-->
<!--        :finished="finished"-->
<!--        @onLoad="onLoad"-->
<!--        @onRefresh="onRefresh"-->
<!--        :isNoData="isNoData"-->
<!--        :error="error"-->
<!--        :is-higeht-max="true"-->
<!--        :hasAdvPagination="false"-->
<!--    >-->

    <van-pull-refresh
        v-model="refreshingc"
        @refresh="onRefresh"
        :disabled="isRefreshDisable"
        success-text="刷新成功"
        :class="{'none': refreshing}"
    >
      <van-list
          v-if="!isNoData"
          v-model="loadingc"
          :finished="finishedc"
          finished-text="没有更多数据了"
          @load="onLoad"
          :offset="10"
          :error.sync="error"
          :immediate-check="false"
          error-text="请求失败，点击重新加载"
      >

        <div class="rowsBox">
          <HorizontalVideoBox
              class="videoBox"
              :videoInfo="item"
              :videoList="videoList"
              v-for="item in videoList"
              :key="item.id"
              :isCount="true"
          />
        </div>

      </van-list>
      <div v-if="!isNoData" class="isNoData" style="height: 150px"></div>
      <NoData v-if="isNoData"/>
    </van-pull-refresh>

<!--    </PullRefresh>-->
<!--    <Loading v-else />-->
<!--    <div class="refreshing_warp">-->
<!--      <van-pull-refresh v-model="loading" @refresh="onRefresh" :disabled="isRefreshDisable" success-text="刷新成功">-->
<!--        <van-list-->
<!--            v-if="!isNoData"-->
<!--            v-model="loading"-->
<!--            :finished="finished"-->
<!--            finished-text="没有更多数据了"-->
<!--            @load="onLoad"-->
<!--            :offset="10"-->
<!--            :error.sync="false"-->
<!--            :immediate-check="false"-->
<!--            error-text="请求失败，点击重新加载"-->
<!--        >-->
<!--          <HorizontalVideoBox-->
<!--              class="videoBox"-->
<!--              :videoInfo="item"-->
<!--              :videoList="videoList"-->
<!--              v-for="item in videoList"-->
<!--              :key="item.id"-->
<!--              :isCount="true"-->
<!--          />-->
<!--        </van-list>-->
<!--        <div v-if="!isNoData" style="height: 150px"></div>-->
<!--        <NoData v-if="isNoData"/>-->
<!--      </van-pull-refresh>-->
<!--    </div>-->
  </div>
</template>
<script>
// import PullRefresh from "@/components/PullRefresh/index.vue";
import {querySubModuleList} from "@/api/app";
import {AdType, getAdItem, jumpAdv} from "@/utils/getConfig";
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import NoData from "@/components/NoData/index.vue";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import OricilegeInfo from "@/views/movies/widget/oricilegeInfo.vue";
import Loading from "@/components/Loading/index.vue";
import {List,PullRefresh} from "vant";
// import {List, NoticeBar, PullRefresh} from 'vant';

export default {
  name: "VideoListItem",
  props: {
    subModuleId: {
      type: String,
    },
    moduleSort:{
      type: Number
    },
    isDisabledSlide:{
      type: Boolean
    }
  },
  components:{
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    // Loading,
    // [List.name]: List,
    // [PullRefresh.name]: PullRefresh,
     NoData,
     HorizontalVideoBox,
    // PullRefresh,
  },
  computed:{
    loadingc: {
      get() {
        return this.loading;
      },
      set() {
        return this.loading;
      },
    },
    finishedc: {
      get() {
        return this.finished;
      },
      set() {
        return this.finished;
      },
    },
    refreshingc: {
      get() {
        return this.refreshing;
      },
      set() {
        return this.refreshing;
      },
    },
  },
  data(){
    return {
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      videoList: [],
    }
  },
  mounted(){
    this.querySubModuleList('refresh');
  },
  methods:{
    goAdv(item) {
      jumpAdv(item);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.querySubModuleList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.querySubModuleList('refresh');
    },
    async querySubModuleList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        subModuleId: this.subModuleId,
        moduleSort: this.moduleSort || 1,
      };
      let res = await this.$Api(querySubModuleList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      const list = res.data.allVideoInfo || [];
      if (type === "refresh") {
        this.videoList = list;
      } else {
        this.videoList = this.videoList.concat(list);
      }
      if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
      }
      if (!res.data.hasNext) {
        this.finished = true;
      }
    },
  }
}
</script>
<style scoped lang="scss">
.videoListItem{
  height: calc(100vh - 160px);
  //overflow-y: auto;
  //overflow-x: hidden;
  //-webkit-overflow-scrolling: touch;

  .swiperBox {

    .swipe {
      width: 100vw;
      height: calc(100vw / 2.4);

      .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: rgb(43, 39, 72);
      }

      /deep/ .van-swipe__indicators {
        left: auto;
        right: 0;
        align-items: center;
      }

      /deep/ .van-swipe__indicator {
        width: 6px;
        height: 6px;
        background-color: rgba(148, 214, 218, 0.3);
      }

      /deep/ .van-swipe__indicator--active {
        width: 9px;
        height: 9px;
        background-color: #94d6da !important;
      }

      /deep/ img {
        object-fit: fill !important;
      }

      /deep/ .van-image__error{
        border-radius: 0;
      }
    }
  }

  .barTopic {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    // justify-content: space-between;
    //padding: 12px 0;
    padding: 0 10px;
    margin-top: 20px;
    font-size: 10px;
    color: #000000;
    text-align: center;
    //border-bottom: solid rgb(230, 230, 230) 1px;
    box-sizing: border-box;
    overscroll-behavior: contain;

    .barTopicItem {
      //width: 86px;
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
    }

    .barTopicItem:last-child {
      padding-right: 30px;
    }

    .topicImg {
      width: 50px;
      height: 50px;
      margin-bottom: 16px;
    }

    img {
      width: 42px;
      height: 42px;
    }

  }

  .rowsBox {
    margin-top: 12px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .videoBox {
      width: 174px;
      margin-bottom: 12px;
    }
  }
}
</style>
