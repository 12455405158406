<template>
 <div class="animationItem overflow-y-auto" :id="scrollId">
  <!-- 专题组合样式(组合样式) -->
  <VideoModuleItem :jingangType="2" :isBtn="true" :bannerType="bannerType" :advType="advType" :subModuleId="item.id" :moduleSort ="1" v-if="item.showType === 1"></VideoModuleItem>
  <!-- 最新列表(列表 所有帖子列表) -->
  <VideoListItem :subModuleId="item.id" :moduleSort ="1" v-if="item.showType === 2"></VideoListItem>
  <!-- 所有专题+列表样式(专题合集  总专题+ 所有帖子列表) -->
  <VideoCollectionItem :subModuleId="item.id" v-if="item.showType === 5"></VideoCollectionItem>
 </div>
</template>
<script>
import {AdType, getAdItem} from "@/utils/getConfig";
import VideoModuleItem from "@/views/home/videoModuleItem/index.vue";
import VideoListItem from "@/views/videoModule/videoListItem/index.vue";
import VideoCollectionItem from "@/views/videoModule/videoCollectionItem/index.vue";

export default {
 components: {
  VideoCollectionItem,
  VideoListItem,
  VideoModuleItem,
 },
 props: {
  item: {
   type: Object,
   default: () => {},
  },
  index: {
   type: Number,
   default: 0,
  },
  scrollId: {
   type: String,
   default: "",
  },
 },
 data() {
  return {
   bannerType: AdType.VIDEOHOMEADV,
   activeIndex: 0,
   modulesList: [],
   bannerList: [],
   jingangArea: [],
   advType: AdType.COLUMN_ADV,
   prefixKey: "videoModule_page_",
  };
 },
 mounted() {
  this.bannerList = getAdItem(AdType.COMMUNITY_RECOMMEND);
  let appConfig = this.$store.getters.appConfig;
  this.jingangArea = appConfig.jingangArea.list || [];
 }
};
</script>

<style scoped lang="scss">
.animationItem {
 height: calc(100vh - 130px);
}
</style>
